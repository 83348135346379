@import "../../styles/utils.scss";

.asset_generation {
  display: flex;
  height: calc(100vh - 64px - $footer-height);

  .container {
    flex: 1;
    padding: 25px 106px 37px 42px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;

      .back_button {
        display: flex;
        align-items: center;
        gap: 7.2px;

        span {
          font-size: 12px;
          font-weight: 700;
          line-height: 22px;
        }
      }

      .current_date {
        font-size: 12px;
        line-height: 16px;
        color: $grey-darker;
      }
    }

    .iframe_container {
      position: relative;
      background-color: $white;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .iframe {
        position: absolute;
        right: 0;
        height: 147%;
        width: 143%;
        transform: scale(0.7);
        transform-origin: 100% 0;
        margin-top: -19px;
      }
    }
  }
}
