// bootstrap
$container-max-widths: (
  xl: 1140px,
) !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;
$grid-gutter-width: 30px;
// fonts
$primary-font: "Montserrat", sans-serif;
$footer-height: 41px;
// color names
$white: #fff;
$black: #000;
$red: #f40000;
$blue: #0000ff;
$orange: #ec721c;

$grey-dark: #c2bbbb;
$grey-darker: #6e6e6e;
$gray: #f1f1f1;
$grey2: #d4d4d4;
$counter-color: #999;
$light-red: #ffeded;
$light-yellow: #fff9d7;
$black2: #565656;
// color scheme
$primary-color: $blue;
$secondary-color: $red;
$background-color: #edf3f7;
$primary-text-color: $black;
$secondary-text-color: $blue;

$checkbox-size: 16px;
$checkbox-border: $black;
$checkbox-selected: $white;
