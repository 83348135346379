@import "../../styles/utils.scss";

.sidebar {
  position: relative;
  width: 273px;
  height: calc(100vh - 64px - $footer-height);
  background-color: $white;
  box-shadow: 0 15px 20px 0 rgba($grey-dark, 0.2);
  border-top: 5px solid var(--accent-color);
  padding-bottom: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  @include styled-scrollbar();
  form {
    .prompts_container {
      padding: 0 24px 0 19px;
    }

    .form_group {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 2px;
      margin-bottom: 14px;

      &.slider {
        label {
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 32px;
        }
      }

      &.checkbox {
        flex-direction: row;
        align-items: center;
        gap: 7px;

        input:checked {
          background-color: red;
        }
      }
    }

    .slider {
      width: 100%;

      .thumb {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        filter: drop-shadow(0px 3px 6px rgba(244, 0, 0, 0.2));
        border: 1px solid $red;
        background-color: $white;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .track {
        height: 3px;
        background-color: $grey2;
      }

      .mark {
        top: -38px;
        font-size: 8px;
        line-height: 24px;
        color: $grey-darker;
        transform: translateX(-25%);
        cursor: pointer;

        &:first-of-type {
          transform: translateX(0%);
        }

        &:last-of-type {
          transform: translateX(-50%);
        }

        &::after {
          content: "";
          width: 3px;
          height: 3px;
          border: 50%;
          background-color: $grey-darker;
          position: absolute;
          top: 90%;
          right: 50%;
          transform: translate(50%, -50%);
        }

        &.active {
          &::after {
            background-color: $red;
          }
        }
      }
    }
  }
}
.red {
  border-top: 5px solid $red;
}
.orange {
  border-top: 5px solid $orange;
}
