@import "../../styles/utils.scss";

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 41px;
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: $white;
    font-size: 12px;
    line-height: 18px;
    margin: 0 auto;
    text-align: center;
  }
}
