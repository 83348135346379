@import "../../styles/utils.scss";

.select_container {
  border: 1px solid $grey-darker;
  border-radius: 8px;
  overflow: hidden;

  .selected_value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $white;
    color: $black;
    padding: 2px 10px;
    cursor: pointer;

    &.disabled {
      background-color: $gray;
    }

    .text {
      display: flex;
      align-items: center;
      gap: 9px;
      p {
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .image_container {
        width: 28px;
        height: 28px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .icon {
      .image {
        transition: all 0.3s;
      }

      &.active {
        .image {
          rotate: 180deg;
        }
      }
    }
  }

  .options_container {
    padding-right: 10px;

    .options {
      display: flex;
      flex-direction: column;
      background-color: $white;
      transition: all 0.5s;
      max-height: 0;
      overflow: auto;
      padding: 0 10px;
      gap: 5px;

      &.active {
        max-height: 200px;
      }

      .option {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grey2;

        gap: 2px;
        cursor: pointer;
        p {
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        .image_container {
          width: 33px;
          height: 33px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .no_values {
          text-align: center;
          color: $grey-darker;
          width: 100%;
          padding: 10px 0;
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background: #e1e1e1;
        border: none;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $grey2;
      }

      &::-webkit-scrollbar-thumb:active {
        background: $grey2;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border: 0 none $white;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track:hover {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-track:active {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    }
  }
}
