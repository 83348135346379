@import "../../styles/utils.scss";

.container {
  width: 240px;
  max-height: 400px;
  overflow: auto;
  @include styled-scrollbar();
  padding-bottom: 20px;

  .inner_container {
    &:not(:nth-child(3)) {
      border-bottom: 1px solid $grey2;
    }
    > p {
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 11px;
      padding-left: 14px;
    }
    > p {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 11px 0;
      padding-left: 14px;
    }
    .image {
      width: 128.455px;
      height: 128.91px;
      margin: 20px auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    > div {
      margin-top: 30px;
    }
  }
  .slider_container {
    padding-bottom: 35px;
    .slider {
      position: relative;
      width: 80%;
      padding: 0 20px;
      margin: 30px auto 0 auto;

      .thumb {
        width: 34px;
        height: 9px;
        border-radius: 30px;
        filter: drop-shadow(0px 3px 6px rgba(244, 0, 0, 0.2));
        border: 1px solid $red;
        background: linear-gradient(341deg, #e4e4e4 20.2%, #fff 111.14%);
        transform: translateY(-50%);
        cursor: pointer;
        box-shadow:
          -2px 4px 10px 0px rgba(0, 0, 0, 0.15),
          0.75px 0.5px 1.5px 0px rgba(244, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }

      .active_thumb {
        border: 1px solid $red;
        outline: none;
        background-color: $red;
      }

      .track {
        height: 1.75px;
        background-color: $grey2;

        &:first-of-type {
          background-color: $red;
        }
      }

      .mark {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
        font-size: 8px;
        line-height: 24px;
        color: $grey-darker;
        transform: translateX(-35%);
        cursor: pointer;
        background-color: $grey2;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid $grey2;

        &:last-of-type {
          background-color: $white;
        }

        &::after {
          content: "";
          width: 1px;
          height: 7px;
          background-color: $grey2;
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .current_value {
        position: absolute;
        top: -23px;
        right: 50%;
        transform: translate(50%, 0);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-bottom: 0.5px solid $red;
        padding: 0 5px;
      }
    }
    &.with_numbers {
      .slider {
        .mark {
          background-color: transparent;
          border-color: transparent;
          font-size: 13px;
          color: $grey2;
        }
      }
    }
  }
  .hdri {
    margin-top: 20px;
    border-top: 1px solid $grey2;
    padding: 10px 20px;
    > p {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 11px 0;
    }
    .image {
      width: 147px;
      height: 147px;
      margin: 20px auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 20px;
    .btn {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      color: #505050;
      border-radius: 48px;
      background: $grey2;
      padding: 5px 16px;
      border: none;
      min-width: 67px;
      cursor: pointer;
    }
  }
  .tabs {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-evenly;
    .tab {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 10px;
      border-bottom: 0.5px solid transparent;
      padding-bottom: 5px;
      cursor: pointer;
      @include animated();
      &:hover,
      &.active {
        text-shadow: 0 0 1px $black;
      }
      &.active {
        border-bottom: 0.5px solid $red;
      }
    }
  }

  .slider_label {
    padding: 0 20px;
    font-size: 11.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
