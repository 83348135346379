@import "../../styles/utils.scss";

.dashboard_container {
  padding: 70px 123px;

  h1 {
    padding-bottom: 18px;
    border-bottom: 1px solid $grey2;
    margin-bottom: 24px;
  }

  p {
    max-width: 856px;
    margin-bottom: 70px;
  }

  button {
    padding: 0;
    border: 0;
    width: 208px;
    height: 208px;
    background: url("../../assets/images/white-cap.png");
    background-position: center;
    background-size: contain;
    font-size: 16px;
    line-height: 22px;
    margin-right: 25px;
    cursor: pointer;

    &:hover {
      background: url("../../assets/images/red-cap.png");
    }
  }
}
