@import "variables";

// breakpoints
@mixin media-breakpoint-down($name) {
  $max: map-get($grid-breakpoints, $name);
  $max: if($max and $max > 0, $max - 0.02, null);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// animate
@mixin animated() {
  transition: all 0.2s ease;
}

// alignment
@mixin absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// other
@mixin placeholder-color($color) {
  ::placeholder {
    color: $color;
  }

  :-ms-input-placeholder {
    color: $color;
  }

  ::-ms-input-placeholder {
    color: $color;
  }
}

@mixin hide-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin styled-scrollbar() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grey2;
  }

  &::-webkit-scrollbar-thumb:active {
    background: $grey2;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border: 0px none $white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track:hover {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-track:active {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@mixin styled-scrollbar-popup() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(228, 228, 228, 1), rgba(255, 255, 255, 1);
    border-radius: 10px;
    border: 0.5px solid $red;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grey2;
  }

  &::-webkit-scrollbar-thumb:active {
    background: $grey2;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border: 0px none $white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track:hover {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-track:active {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
