@import "../../styles/utils.scss";

.button_container {
  background-color: $white;
  width: 273px;
  padding: 22px 40px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  left: 0;

  button {
    width: 100%;
    padding: 12px;
    border: 2px solid var(--accent-color);
    border-radius: 24px;
    background-color: $white;
    color: var(--accent-color);
    font-size: 14px;
    cursor: pointer;
    z-index: 99;
    @include animated();

    &:not(:disabled) {
      &:hover {
        background-color: var(--accent-color);
        color: $black;
        border: 2px solid var(--accent-color);

        .particles {
          display: block;
        }
      }
    }

    &:disabled {
      background-color: $gray;
      border-color: $grey-darker;
      color: $grey-darker;
    }

    .particles {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: none;
      z-index: -1;
    }
  }
}
