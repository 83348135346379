@import "utils";

.clearfix {
  clear: both;
}

a,
input,
textarea,
button {
  @include animated();

  &:focus {
    outline: none;
  }
}

body {
  font-family: $primary-font;
  color: $primary-text-color;
  font-weight: 400;
}

ul,
li {
  list-style: none;
}

.container {
  background-color: $gray;
  padding-bottom: $footer-height;
}

* {
  scroll-behavior: smooth;
}

input,
textarea {
  border-radius: 8px;
  border: 1px solid $grey-darker;
  color: $black;
  font-size: 13px;
  line-height: 18px;

  &::placeholder {
    color: #999;
  }

  &.invalid {
    border-color: $red;
  }
}

textarea {
  resize: none;
  width: 100%;
  height: 136px;
  padding: 8px 18px;
}

.checkbox {
  position: absolute;
  opacity: 0;
  width: 100%;
  z-index: 99;
  cursor: pointer;

  // Text
  & + label {
    display: inline-block;
    position: relative;
    padding-left: $checkbox-size + 10;
    color: $black;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    // Checkbox
    &:before {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background: transparent;
      width: $checkbox-size;
      height: $checkbox-size;
      border-radius: 4px;
      transition: 200ms ease-in-out all;
      border: 1px solid $black;
      cursor: pointer;
    }

    // Tick
    &:after {
      position: absolute;
      display: block;
      top: 3.5px;
      left: 7px;
      content: "";
      width: 3px;
      height: 7px;
      border-right: 1.5px solid transparent;
      border-bottom: 1.5px solid transparent;
      transform: rotate(45deg);
      transition: 200ms ease-in-out all;
    }
  }

  // Checked
  &:checked {
    & + label {
      &:before {
        background: $checkbox-selected;
        box-shadow: none;
        border: 1px solid $black;
      }

      &:after {
        border-color: $black;
      }
    }
  }

  &:disabled {
    & + label {
      &:before {
        background: $gray;
      }

      &:after {
        border-color: transparent;
      }
    }
  }

  &:checked:disabled {
    & + label {
      &:before {
        background: $grey2;
      }

      &:after {
        border-color: $black;
      }
    }
  }
}

.form-checkbox {
  position: relative;
  display: block;
  margin: 0 0 15px;
}

.card {
  background-color: $white;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.popup-content {
  div {
    z-index: 99 !important;
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 13px;
    border-bottom: 1px solid $grey2;

    p {
      font-size: 13px;
      line-height: normal;
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      .icon {
        width: 10px;
        height: 10px;
        cursor: pointer;
        transition: fill 0.4s;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: inherit;
          width: inherit;
        }

        &:hover {
          path {
            fill: $red;
          }
        }

        &.active {
          background-color: $black;
          border-radius: 50%;
          border: none;
          path {
            fill: $white;
          }
          &:hover {
            background-color: $white;
            path {
              fill: $red;
            }
          }
        }
      }
    }
  }
}

.filter_with_image {
  .image {
    width: 40px;
    height: 40px;
    > div {
      display: flex;
    }
  }
}

.select {
  border-radius: 8px;
  border: 1px solid $grey-darker;
}

.tooltip_message {
  width: 240px;
  padding: 18px;
  background-color: $light-yellow;
  color: $black2;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
