@import '../../styles/utils.scss';

.right_sidebar {
  position: relative;
  width: 80px;
  height: calc(100vh - 64px - $footer-height);
  background-color: $white;
  padding: 45px 0;
  overflow: auto;
  display: none;
  border-top: 5px solid var(--accent-color);
  @include hide-scrollbar();
  &.active {
    display: block;
  }
  > p {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
  .menu_items {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .menu_item {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    .image {
      width: 100%;
      height: 52px;
      padding: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include animated();
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    p {
      font-size: 11.5px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $counter-color;
      text-align: center;
      @include animated();
    }
    &:hover,
    &.active {
      .image {
        background-color: #ffeded;
        svg {
          path {
            fill: var(--accent-color);
          }
        }
      }
      p {
        color: $black;
      }
    }
  }
  .separator {
    border-bottom: 1px solid $grey2;
    width: 100%;
    margin: 31px 0;
  }

  .share_modal {
    padding: 25px 58px 43px;
    background-color: $white;
    text-align: center;
    border-radius: 16px;
    max-width: 595px;

    h2 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    p {
      font-size: 14px;
      margin-bottom: 46px;
    }

    .buttons {
      display: flex;
      gap: 21px;

      button {
        cursor: pointer;
        padding: 13px 0;
        min-width: 145px;
        font-size: 12px;
        background-color: $grey2;
        border-radius: 24px;
        border: 2px solid transparent;
        @include animated();

        &:hover {
          background-color: $white;
          border: 2px solid #999999;
        }
      }
    }
    .ctnRetouch {
      text-align: left;
      margin-top: 20px;
      label {
        margin-bottom: 8px;
        display: block;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
      }
      button {
        margin-top: 12px;
        border: 2px solid var(--accent-color);
        background-color: var(--accent-color);
        color: $white;
        border-radius: 24px;
        cursor: pointer;
        padding: 13px 0;
        min-width: 145px;
        font-size: 12px;
        @include animated();
        &:hover {
          background-color: $white;
          border: 2px solid var(--accent-color);
          color: var(--accent-color);
        }
      }
    }
  }
}
