@import "../../styles/utils.scss";

.signin {
  height: calc(100vh - $footer-height - 64px);
  background-color: $red;

  .signin_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    width: 380px;
    margin: 0 auto;

    h1 {
      font-size: 38px;
      font-weight: 400;
      line-height: 48px;
      text-align: center;
      margin-bottom: 50px;

      span {
        font-weight: 600;
      }
    }

    p {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 30px;
    }

    .button {
      width: 100%;
      padding: 10px 50px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $black;
      border-radius: 100px;
      border: 2px solid $black;
      background-color: $white;
    }

    .form_group {
      max-width: 327px;
      width: 100%;
    }

    form {
      max-width: 327px;
      width: 100%;

      .form_group {
        width: 100%;
        margin-bottom: 30px;

        input {
          width: 100%;
          padding: 10px 50px;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: $black;
          border-radius: 100px;
          border: 2px solid $black;
          background-color: $white;

          &[type="submit"] {
            cursor: pointer;
            &:hover {
              background-color: $gray;
            }
          }
        }

        .error {
          font-size: 13px;
          color: $black;
          font-weight: 600;
          text-align: center;
          height: 14px;
        }
      }
    }
  }
}
