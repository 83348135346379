@import "../../styles/utils.scss";

.message {
  @include absolute-center();
  p {
    text-align: center;
    color: $counter-color;
    font-size: 12px;
    line-height: 16px;
  }
}
