@import "../../styles/utils.scss";

.header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 54px 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  height: 64px;
  z-index: 99;

  &.signin_page {
    background-color: $red;
    padding: 0 107px;
    align-items: flex-end;
    .logo {
      p {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
    }
  }

  .logo {
    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 16.8px;
    }
    img {
      width: auto;
      height: 55px;
    }
  }

  .logout {
    cursor: pointer;
    background: none;
    font-size: 14px;
    line-height: 16.8px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-family: inherit;
  }

  .user_details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;

    p {
      font-size: 14px;
      line-height: 16.8px;
    }

    .user_icon {
      width: 23px;
      height: 23px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
