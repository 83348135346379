@import "../../styles/utils.scss";

.popup {
  width: 273px;
  min-height: 100px;
  max-height: 300px;

  .popup_inner {
    width: 100%;
    min-height: inherit;
    max-height: inherit;
    padding: 16px 22px;
    overflow: hidden;
    > div {
      overflow: auto;
      width: 100%;
      min-height: inherit;
      max-height: inherit;
      padding: 0 18px 16px 0;

      @include styled-scrollbar-popup();
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
        padding: 6px;
        cursor: pointer;
        @include animated();

        .image {
          width: 50px;
          height: 50px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        p {
          color: #333;
          text-align: center;
          font-size: 11.5px;
          line-height: 16px;
          word-break: break-word;
        }

        &:hover,
        &.active {
          background-color: $light-red;
        }
      }
    }

    .linear {
      display: flex;
      flex-direction: column;
      gap: 7px;

      .item {
        padding: 5px 14px;
        cursor: pointer;
        @include animated();

        p {
          font-size: 11.5px;
          line-height: 14px;
        }

        &:hover,
        &.active {
          background-color: $light-red;
        }
      }
    }
  }
}
