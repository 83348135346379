@import "../../styles/utils.scss";

.container {
  width: 240px;
  padding-bottom: 20px;

  .inner_container {
    padding: 10px 0 27px 0;
    &:not(:nth-child(4)) {
      border-bottom: 1px solid $grey2;
    }
    > p {
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 11px;
      padding-left: 14px;
    }
    .grid_container {
      padding: 0 18px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      gap: 13px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: center;
        gap: 4px;
        cursor: pointer;
        @include animated();
        p {
          font-size: 11.5px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &:not(:hover) {
          &:not(.active) {
            filter: grayscale(1);
          }
        }
        .active {
          filter: grayscale(0);
        }
      }
      .disabled {
        filter: grayscale(1);
        cursor: default;
      }
    }
  }
  .defocus {
    padding-bottom: 20px;
    .slider {
      position: relative;
      width: 80%;
      padding: 0 20px;
      margin: 40px auto 0 auto;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.5px;
        height: 8px;
        background-color: $grey2;
        transform: translate(0, -40%);
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 1.5px;
        height: 8px;
        background-color: $grey2;
        transform: translate(0, -40%);
      }

      .thumb {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        filter: drop-shadow(0px 3px 6px rgba(244, 0, 0, 0.2));
        border: 1px solid $red;
        background-color: $white;
        transform: translateY(-50%);
        cursor: pointer;
        box-shadow:
          -2px 4px 10px 0px rgba(0, 0, 0, 0.15),
          0.75px 0.5px 1.5px 0px rgba(244, 0, 0, 0.3);
      }

      .active_thumb {
        border: 1px solid $red;
        outline: none;
        background-color: $red;
      }

      .track {
        height: 1.75px;
        background-color: $grey2;

        &:first-of-type {
          background-color: $red;
        }
      }

      .mark {
        bottom: -35px;
        font-size: 13px;
        line-height: 24px;
        color: $grey-darker;
        transform: translateX(-50%);
        cursor: pointer;

        &::after {
          content: "";
          width: 1px;
          height: 7px;
          background-color: $grey-darker;
          position: absolute;
          top: 0;
          right: 50%;
          transform: translate(50%, -50%);
        }

        &.active {
          &::after {
            background-color: $red;
          }
        }
      }
      .current_value {
        position: absolute;
        top: -20px;
        right: 50%;
        transform: translate(50%, 0);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-bottom: 0.5px solid $red;
        padding: 0 5px;
      }
    }
  }
  .rotation {
    padding: 0 20px;
    padding-bottom: 30px;
    .slider {
      position: relative;
      width: 100%;
      margin-top: 20px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1.5px;
        height: 8px;
        background-color: $grey2;
        transform: translate(0, -40%);
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 1.5px;
        height: 8px;
        background-color: $grey2;
        transform: translate(0, -40%);
      }

      .thumb {
        width: 34px;
        height: 9px;
        border-radius: 30px;
        filter: drop-shadow(0px 3px 6px rgba(244, 0, 0, 0.2));
        border: 1px solid $red;
        background: linear-gradient(341deg, #e4e4e4 20.2%, #fff 111.14%);
        transform: translateY(-50%);
        cursor: pointer;
        box-shadow:
          -2px 4px 10px 0px rgba(0, 0, 0, 0.15),
          0.75px 0.5px 1.5px 0px rgba(244, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        span {
          display: block;
          &.bullet {
            width: 3px;
            height: 3px;
            background-color: $red;
            border-radius: 50%;
          }
          &.arrow {
            width: 2px;
            height: 3px;
            background: url("../../assets/icons/arrow_filled.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            &.right {
              rotate: (180deg);
            }
          }
        }
      }

      .active_thumb {
        border: 1px solid $red;
        outline: none;
        background-color: $red;
      }

      .track {
        height: 1.75px;
        background-color: $grey2;

        &:first-of-type {
          background-color: $red;
        }
      }

      .mark {
        bottom: -35px;
        font-size: 13px;
        line-height: 24px;
        color: $grey-darker;
        transform: translateX(-50%);
        cursor: pointer;

        &::after {
          content: "";
          width: 1px;
          height: 7px;
          background-color: $grey-darker;
          position: absolute;
          top: 0;
          right: 50%;
          transform: translate(50%, -50%);
        }

        &.active {
          &::after {
            background-color: $red;
          }
        }
      }
    }
    .current_value {
      padding: 4px 14px;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 4px;
      border: 0.25px solid $counter-color;
      background: #e8e8e8;
      width: max-content;
      margin-left: auto;
    }
  }
  .buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 20px;
    .btn {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      color: #505050;
      border-radius: 48px;
      background: $grey2;
      padding: 5px 16px;
      border: none;
      min-width: 67px;
      cursor: pointer;
    }
  }
}
