@import "utils";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

h1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

ul,
ol {
  list-style: decimal inside;
}

ul {
  list-style: disc inside;
}

strong {
  font-weight: bold;
}

b {
  font-family: $primary-color;
  font-weight: bold;
}

em,
i {
  font-style: italic;
}
