@import "../../styles/utils.scss";

.filters {
  margin-bottom: 23px;
  padding: 42px 0 13px 0;
  border-bottom: 1px solid $grey2;
  > p {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 16px;
    padding-left: 19px;
  }
  .filters_container {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}
