@import "../../styles/utils.scss";

.buttons_container {
  position: fixed;
  width: 66px;
  top: 132px;
  right: 0;
  background-color: $white;
  border-top: 4px solid $red;
  padding: 22px 0 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
