@import "../../styles/utils.scss";

.form_group {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 20px;
  position: relative;
  label {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
  }
  .prompt_container {
    position: relative;
    .counter {
      font-size: 9px;
      line-height: 10px;
      width: 100%;
      text-align: right;
      font-style: normal;
      font-weight: 400;
      color: $counter-color;
      margin-left: auto;
    }
    .error {
      color: $red;
      font-size: 10px;
      line-height: 8px;
      width: 100%;
      position: absolute;
      top: 10px;
      left: 0;
    }
  }
}
