@import "../../styles/utils.scss";

.filter {
  position: relative;
  display: flex;
  gap: 11px;
  align-items: center;
  padding: 0 24px 0 41px;

  &:hover {
    .image {
      > div {
        path,
        g {
          fill: var(--accent-color);
        }
      }
    }
  }

  .image {
    width: 20px;
    height: 24px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      width: inherit;
    }
  }

  .text {
    cursor: pointer;

    .label {
      font-size: 11.5px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
      color: $grey-darker;
    }

    .value {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $counter-color;
      &.error {
        color: $red;
      }
    }

    &.active {
      .value {
        color: $black;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 19px;
    height: 11px;
    width: 5px;
    background: url("../../assets/icons/arrow.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(0, -50%);
  }
}
