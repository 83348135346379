@import "../../styles/utils.scss";

.button {
  border: none;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  @include animated();

  .icon {
    path {
      @include animated();
    }
  }

  p {
    font-size: 9px;
    line-height: 16px;
    color: $grey-darker;
    @include animated();
  }

  &:hover {
    p {
      color: $black;
    }

    .icon {
      path {
        fill: $black;
      }
    }
  }
}
