@import "../../styles/utils.scss";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($grey-darker, 0.4);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white;

  .loader_container {
    .spinner {
      width: 48px;
      height: 48px;
      border: 5px solid $white;
      border-bottom-color: $grey-darker;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
  .loaderMessage {
    margin-top: 35px;
    font-weight: 600;
    background-color: rgba($black2, 0.7);
    padding: 10px;
  }
}
