@import "../../styles/utils.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($black, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .popup_inner {
    position: relative;

    .close {
      position: absolute;
      top: 11px;
      right: 20px;

      &::after {
        content: "X";
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        font-family: Montserrat, $primary-font;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
