@import "../../styles/utils.scss";

.search_container {
  margin-bottom: 5px;
  form {
    position: relative;
    width: 100%;
    input {
      font-size: 11.5px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      border-radius: 20px;
      background-color: $gray;
      border: none;
      padding: 4px 24px 4px 14px;
    }
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("../../assets/icons/search_icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 11px;
      transform: translate(0, -50%);
    }
  }
}
